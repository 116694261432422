export const LOGIN = "/";
export const CUSTOMERLIST = "/customers";
export const VEHICLESNEGOTIATING = "/vehicles/negotiating";
export const VEHICLESPURCHASED = "/vehicles/purchased";
export const APPOINTMENTLIST = "/appointments";
export const STAFFLIST = "/staff";
export const TRUCKINGCOMPANIESLIST = "/truckingcompanies";
export const STORELIST = "/store";
export const LOCATIONLIST = "/location";
export const PROFILE = "/profile";
export const VEHICLEDETAIL = "/vehicles/details";
export const VEHICLEINFO = "/vehicles/details/info/:id";
export const MDA = "/vehicles/details/mda/:id";
export const VEHICLEESINGDOCUMENT = "/vehicles/details/esigndocument";
export const VEHICLECHECKS = "/vehicles/details/checks";
export const VEHICLENOTE = "/vehicles/details/note";
export const CUSTOMERINFO = "/vehicles/details/customer/info";
export const SOLDANDUNSOLDVEHICLES = "/vehicles/soldandunsold";
export const STAFFADD = "/staff/update";
export const VEHICLELOAN = "/vehicles/details/loan"
export const DOCUMENTSUPLOAD = "/vehicles/details/documentsupload"
export const CUSTOMERUPDATE = '/customers/update'
export const CONFIGURATION = '/vehicles/details/loan/configuration'
